import { lazy, Suspense, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
/* import singletonFetch from './singletonFetch';
import MenuItem from '@mui/material/MenuItem'; */
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
//import { User, fakeData, usStates } from './makeData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
/* import axios from 'axios'; */
const { REACT_APP_API_ENDPOINT } = process.env;

const csvConfig = mkConfig({
  fieldSeparator: ';',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
  });

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  //const [garages, setGarages] = useState([]);
 /*  const [loading, setLoading] = useState(true); */


  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: '_id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'marca',
        header: 'Marca',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.marca,
          helperText: validationErrors?.marca,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              marca: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'modello',
        header: 'Modello',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.modello,
          helperText: validationErrors?.modello,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              modello: undefined,
            }),
        },
      },
      {
        accessorKey: 'targa',
        header: 'targa',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.targa,
          helperText: validationErrors?.targa,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              targa: undefined,
            }),
        },
      },
/*       {
        //  accessorKey: `garageId`,
        accessorFn: (row) => row.autoindeposito.recapito,
        id: 'garageId',
        header: 'GarageId',
        editVariant: 'select',
        editSelectOptions: garages._id,
      //  editSelectOptions: JSON.stringify(garages),
        muiEditTextFieldProps: () => ({
            children: garages.map((garage, index) => <MenuItem key={garage.recapito} value={garage._id}>
              {garage.recapito}
            </MenuItem>),
            select: true,
            error: !!validationErrors?.garage,
            helperText: validationErrors?.garage,
          })
      }, */
      {
        accessorKey: 'recapito',
        header: 'deposito',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.recapito,
          helperText: validationErrors?.recapito,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              recapito: undefined,
            }),
        },
      },
    ],
    [validationErrors],
  );


  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser();
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =  useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser(row.original.id);
    }
  };

  const handleExportRows = (rows, fileName = "report-parco-auto") => {
  const rowData = rows.map((row) => row.original);

  // Crea una nuova configurazione con il nome personalizzato
  const customConfig = { ...csvConfig, filename: fileName };

  const csv = generateCsv(customConfig)(rowData);
  download(customConfig)(csv);
/*   const csv = generateCsv(csvConfig)(rowData);
  // Modifica il nome del file al volo
  download({ ...csvConfig, filename: fileName })(csv);
  download(csvConfig)(csv); */
};



  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({row, table }) => (
      <>
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Inserisci nuova auto
      </Button>
        <Button variant="outlined"
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Esporta Tabella
        </Button>

        <Button variant="contained"
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
        Esporta Righe Selezionate
        </Button>
      </>
    ),
    state: {
    isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
      showLoadingOverlay: isFetchingUsers ,
    },
  });

  return <MaterialReactTable table={table} />;
};

//CREATE hook (post new user to api)
function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(
        ['users'],
        (prevUsers) =>
          [
            ...prevUsers,
            {
              ...newUserInfo,
              id: (Math.random() + 1).toString(36).substring(7),
            },
          ],
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['mezzi'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['mezzi'],
    queryFn: async () => {
      //send api request here

      const fetchURL = new URL(`${REACT_APP_API_ENDPOINT}/api/flotta/mezzi`);
      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      const json = (await response.json());
      return json.data;

      //*********
/*       await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve(fakeData); */
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
/*       queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
        ),
      ); */
      console.log("optimistic mutate log")
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['mezzi'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['mezzi'] }), //refetch users after mutation, disabled for demo
  });
}

//react query setup in App.tsx
const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Example />
    </QueryClientProvider>
  );
}

const validateRequired = (value) => !!value.length;
/* const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ); */

function validateUser(user) {
  return {
    marca: !validateRequired(user.marca)  ? 'First Name is Required' : '',
    modello: !validateRequired(user.modello) ? 'Last Name is Required' : '',
    targa: !validateRequired(user.targa) ? 'Incorrect Email Format' : '',
    recapito: !validateRequired(user.recapito) ? 'Incorrect recapito Format' : '',
  };
}
