import { useMemo, useState } from 'react';
//import { GoogleMap, useJsApiLoader, StandaloneSearchBox, LoadScript } from '@react-google-maps/api'
import { addUfficio } from './UfficiApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

import Autocomplete from "react-google-autocomplete";
import './uffici.css';
import axios from 'axios';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,

} from '@mui/material';
//Import Material React Table Translations

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const { REACT_APP_API_ENDPOINT } = process.env;

const queryClient = new QueryClient();

const csvConfig = mkConfig({
  fieldSeparator: ';',
  decimalSeparator: '.',
  useKeysAsHeaders: true,

});


const UfficiAll = () => {
  const [places, setPlaces] = useState({});
  const [location, setLocation] = useState({});
  const [mapCitta, setMapCitta] = useState('');
  const [mapRegione, setMapRegione] = useState('')
  // per nuovo inserimento
  const [values, setValues] = useState({})

  const [validationErrors, setValidationErrors] = useState({});
  const handleExportRows = (rows, fileName) => {
    if (!rows || rows.length === 0) {
      alert("Nessuna riga selezionata!");
      return;
    }

    const rowData = rows.map((row) => row.original);

    // Configurazione CSV con nome personalizzato
    const csvConfig2 = { ...csvConfig, filename: fileName || "uffici_selezionati_esportati" };

    // Genera e scarica il CSV
    const csv = generateCsv(csvConfig2)(rowData);
    download(csvConfig2)(csv);

  };

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: '_id',
        header: 'id',
        filterVariant: 'text', // default
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'regione',
        header: 'Regione',
        filterVariant: 'text', // default
        size: 80,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.regione,
          helperText: validationErrors?.regione,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              regione: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'citta',
        header: 'Città',
        filterVariant: 'text', // default
        size: 80,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.citta,
          helperText: validationErrors?.citta,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              citta: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'denominazione',
        header: 'Denominazione',
        filterVariant: 'text', // default
        size: 100,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.regione,
          helperText: validationErrors?.regione,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              regione: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'indirizzo',
        header: 'Indirizzo',
        filterVariant: 'text', // default
        size: 200,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.recapito,
          helperText: validationErrors?.recapito,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              recapito: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'materiale',
        header: 'Materiale',
        filterVariant: 'text', // default
        size: 200,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.localita,
          helperText: validationErrors?.localita,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              localita: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'MTb',
        header: 'MTB',
        filterVariant: 'text', // default
        size: 50,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.indirizzo,
          helperText: validationErrors?.indirizzo,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              MTb: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'MTh',
        header: 'MTH',
        filterVariant: 'text', // default
        size: 50,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.MTh,
          helperText: validationErrors?.MTh,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              MTh: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'MQ',
        header: 'M2',
        filterVariant: 'text', // default
        size: 50,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.MQ,
          helperText: validationErrors?.MQ,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              MQ: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'lat',
        header: 'lat',
        filterVariant: 'text', // default
        size: 50,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.lat,
          helperText: validationErrors?.lat,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              lat: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
        Cell: ({ cell }) => {
          return <div style={{ color: 'orangered' }}>{cell.getValue()}</div>;
        },
      },
      {
        accessorKey: 'lng',
        header: 'lng',
        filterVariant: 'text', // default
        size: 50,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.lat,
          helperText: validationErrors?.lat,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              lng: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
        Cell: ({ cell }) => {
          return <div style={{ color: 'orangered' }}>{cell.getValue()}</div>;
        },
      },
      {
        accessorKey: 'team',
        header: 'team',
        filterVariant: 'text', // default
        size: 50,

        Cell: ({ cell }) => {
          return <div style={{ color: '#a1e01f' }}>{cell.getValue()}</div>;
        },
      },
    ],
    [validationErrors],
    //end
  );

  //call CREATE hook
  const { mutateAsync: createUfficio, isPending: isCreatingUser } = useCreateUser({ values, location, places, mapRegione, mapCitta });
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUfficiError,
    isFetching: isFetchingUffici,
    isLoading: isLoadingUffici,
    isRefetching: refetch,
  } = useGetUsers();


  //call UPDATE hook
  const { mutateAsync: updateUfficio, isPending: isUpdatingUfficio } = useUpdateUfficio();
  //call DELETE hook
  const { mutateAsync: deleteUfficio, isPending: isDeletingUfficio } = useDeleteUfficio();

  //CREATE action

  const queryClient = useQueryClient();

  const createPostMutation = useMutation({
    mutationFn: addUfficio,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['uffici'] })
      table.setCreatingRow(null);
    },
    onError: (error) => alert(error)
  })

  const handleCreateUser = () => {

    const payload = {
      regione: values.regione,
      citta: values.citta,
      denominazione: values.denominazione,
      indirizzo: places,
      materiale: values.materiale,
      MTb: values.MTb,
      MTh: values.MTh,
      MQ: values.MQ,
      lat: location.lat,
      lng: location.lng,
      team: values.team
    }

    createPostMutation.mutate(payload)

  }

  //UPDATE action
  const handleSaveUser = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUfficio(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    await updateUfficio(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Sicuri di voler eliminare questo ufficio?')) {
      deleteUfficio(row.original._id, row.id);
    }
  };

  const HandleonPlacesChanged = (place) => {
    setPlaces(place.formatted_address);
    let geo = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
    console.log(place.geometry.location.lat())
    setLocation(geo);

    //esperimento:
    var test2 = place.address_components;
    console.log(test2)
    let regioneDaMap;
    let cittaDaMap;
    for (var i = 0; i < test2.length; i++) {
      //test2[i].types.forEach((element) => console.log(element));
      if (test2[i].types[0] === 'administrative_area_level_3') {
        console.log(test2[i].long_name);
        cittaDaMap = test2[i].long_name;
        setMapCitta(cittaDaMap)
      }
      if (test2[i].types[0] === 'administrative_area_level_1') {
        console.log(test2[i].long_name);
        regioneDaMap = test2[i].long_name;
        setMapRegione(regioneDaMap);
      }
      //console.log(test2[i].types)
    }

  }

  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
    },
    getRowId: (row) => row.id,
    localization: {
      actions: 'Azioni',
      and: 'e',
      cancel: 'Cancella',
      changeFilterMode: 'Cambia modalità filtro',
      changeSearchMode: 'Cambia modalità ricerca',
      clearFilter: 'Cancella filtro',
      clearSearch: 'Cancella ricerca',
      clearSelection: 'Cancella selezione',
      clearSort: 'Cancella ordinamento',
      clickToCopy: 'Click per copiare',
      copy: 'Copia',
      collapse: 'Chiudi',
      collapseAll: 'Chiudi tutto',
      columnActions: 'Azioni colonna',
      copiedToClipboard: 'Copia negli appunti',
      dropToGroupBy: 'Crea gruppo da {column}',
      edit: 'Modifica',
      expand: 'Espandi',
      expandAll: 'Espandi tutto',
      filterArrIncludes: 'Includi',
      filterArrIncludesAll: 'Includi tutto',
      filterArrIncludesSome: 'Inclusioni',
      filterBetween: 'Tra',
      filterBetweenInclusive: 'Tra inclusioni',
      filterByColumn: 'Filtra da {column}',
      filterContains: 'Contiene',
      filterEmpty: 'Vuoto',
      filterEndsWith: 'Finisce con',
      filterEquals: 'Uguale',
      filterEqualsString: 'Uguale',
      filterFuzzy: 'Sfocato',
      filterGreaterThan: 'Maggiore di',
      filterGreaterThanOrEqualTo: 'Maggiore o uguale a',
      filterInNumberRange: 'Tra',
      filterIncludesString: 'Contiene',
      filterIncludesStringSensitive: 'Contiene',
      filterLessThan: 'Minore di',
      filterLessThanOrEqualTo: 'Minore o uguale a',
      filterMode: 'Modalità filtro: {filterType}',
      filterNotEmpty: 'Non vuoto',
      filterNotEquals: 'Non uguale',
      filterStartsWith: 'Inizia con',
      filterWeakEquals: 'Uguale',
      filteringByColumn: 'Filtrando da {column} - {filterType} {filterValue}',
      goToFirstPage: 'Vai alla prima pagina',
      goToLastPage: "Vai all'ultima pagina",
      goToNextPage: 'Vai alla pagina successiva',
      goToPreviousPage: 'Vai alla pagina precedente',
      grab: 'Prendi',
      groupByColumn: 'Raggruppa da {column}',
      groupedBy: 'Raggruppa da ',
      hideAll: 'Nascondi tutto',
      hideColumn: 'Nascondi la colonna {column}',
      max: 'Massimo',
      min: 'Minimo',
      move: 'Sposta',
      noRecordsToDisplay: 'Nessun record da mostrare',
      noResultsFound: 'Nessun risultato trovato',
      of: 'di',
      or: 'o',
      pin: 'Blocca',
      pinToLeft: 'Blocca a sinistra',
      pinToRight: 'Blocca a destra',
      resetColumnSize: 'Resetta dimensione colonna',
      resetOrder: 'Resetta ordine',
      rowActions: 'Azioni riga',
      rowNumber: '#',
      rowNumbers: 'Righe',
      rowsPerPage: 'Righe per pagina',
      save: 'Salva',
      search: 'Cerca',
      selectedCountOfRowCountRowsSelected:
        '{selectedCount} di {rowCount} righe selezionate',
      select: 'Seleziona',
      showAll: 'Mostra tutto',
      showAllColumns: 'Mostra tutte le colonne',
      showHideColumns: 'Mostra/Nascondi colonne',
      showHideFilters: 'Mostra/Nascondi filtri',
      showHideSearch: 'Mostra/Nascondi ricerca',
      sortByColumnAsc: 'Ordina da {column} crescente',
      sortByColumnDesc: 'Ordina da {column} decrescente',
      sortedByColumnAsc: 'Ordinato da {column} crescente',
      sortedByColumnDesc: 'Ordinato da {column} decrescente',
      thenBy: ', poi da',
      toggleDensity: 'Cambia densità',
      toggleFullScreen: 'Cambia schermo intero',
      toggleSelectAll: 'Cambia selezione totale',
      toggleSelectRow: 'Cambia selezione riga',
      toggleVisibility: 'Cambia visibilità',
      ungroupByColumn: 'Rimuovi gruppo {column}',
      unpin: 'Sblocca',
      unpinAll: 'Sblocca tutto',
    },

    muiToolbarAlertBannerProps: isLoadingUfficiError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row }) => (
      <>
        <DialogTitle variant="h6">Crea Ufficio</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}
        >
          {/* internalEditComponents */} {/* or render custom edit components here */}
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(place) => HandleonPlacesChanged(place)}
            options={{
              types: ["geocode", "establishment"],

            }}
            defaultValue="Rome"
          />
          {places && (console.log(JSON.stringify(places), JSON.stringify(location), JSON.stringify(mapRegione), JSON.stringify(mapCitta)))}
          <form onSubmit={(e) => e.preventDefault()}>

            {places &&
              <TextField
                label="Regione"
                name="regione"
                id='regione'
                sx={{ input: { color: 'dodgerblue', fontWeight: 'bold' } }}
                /*         onChange={(e) =>
                          setValues({ ...values, [e.target.name]: e.target.value.toUpperCase() })
                        } */
                value={mapRegione || ''}
                fullWidth
                margin="normal"
                required
              />
            }

            {places &&
              <TextField
                label="Citta"
                name="citta"
                id='citta'
                sx={{ input: { color: 'dodgerblue', fontWeight: 'bold' } }}
                //   value={formData.cognome}
                /*       onChange={(e) =>
                     setValues({ ...values, [e.target.name]: e.target.value.toUpperCase() })
                   } */
                //  onChange={(e)=>setCitta(e.target.value)}
                value={mapCitta || ''}
                fullWidth
                margin="normal"
                required
              />
            }

            <TextField
              label="Denominazione"
              name="denominazione"
              id="deneminazione"
              //  value={formData.cognome}
              //  onChange={handleChange}
              //  onChange={(e)=>setDenominazione(e.target.value)}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Indirizzo"
              name="indirizzo"
              id="indirizzo"
              sx={{ input: { color: 'dodgerblue', fontWeight: 'bold' } }}
              value={places}
              /*
            onChange={(e) =>
              setValues({ ...values, [e.target.name]: e.target.value })
            }
              */
              fullWidth
              margin="normal"
              required
              type="text"
            />
            <TextField
              label="Materiale"
              name="materiale"
              id="materiale"
              //   value={row.original.materiale}
              //  onChange={(e)=>setMateriale(e.target.value)}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              // Denominazione
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="MTb"
              name="MTb"
              id="MTb"
              //   value={row.original.MTb}
              //   onChange={handleChange}
              //  onChange={(e)=>setMTb(e.target.value)}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              fullWidth
              margin="normal"
              required
              type="text"
            />
            <TextField
              label="MTh"
              name="MTh"
              id="MTh"
              //  value={row.original.MTh}
              //   onChange={handleChange}
              //  onChange={(e)=>setMTh(e.target.value)}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              fullWidth
              margin="normal"
              required
              type="text"
            />
            <TextField
              label="MQ"
              name="MQ"
              id="MQ"
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              fullWidth
              margin="normal"
              required
              type="text"
            />
            {places &&
              <TextField
                label="lat"
                name="lat"
                id="lat"
                sx={{ input: { color: 'orangered' } }}
                InputLabelProps={{ shrink: true }}
                value={location.lat || ''}
                //   onChange={handleChange}
                /* onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                } */
                fullWidth
                margin="normal"
                required
                type="text"
              />
            }
            {places &&
              <TextField
                label="lng"
                name="lng"
                id="lng"
                InputLabelProps={{ shrink: true }}
                sx={{ input: { color: 'orangered' } }}
                value={location.lng || ''}
                //   onChange={handleChange}
                /* onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                } */
                fullWidth
                margin="normal"
                required
                type="text"
              />
            }
            <TextField
              label="team"
              name="team"
              id="team"
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              fullWidth
              margin="normal"
              required
              type="text"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Modifica ufficio</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}

        >
          <Button
            variant="contained" color="success"
            onClick={() => {
              table.setCreatingRow(true);
            }}
          >
            Crea Ufficio
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              handleExportData(table.getPrePaginationRowModel().rows)
            }
          >
            Esporta CSV
          </Button>
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
          >
            Export Selected Rows
          </Button>
        </Box>

      </>
    ),
    state: {
      isLoading: isLoadingUffici,
      // isSaving: isCreatingUser || isUpdatingUfficio || isDeletingUfficio,
      isSaving: isCreatingUser || isDeletingUfficio,
      showAlertBanner: isLoadingUfficiError,
      showProgressBars: isFetchingUffici,
    },
  });

  return (
    <MaterialReactTable
      table={table}
    />

  )
};

//CREATE hook (post new user to api)
function useCreateUser({ values, location, places }) {
  //  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ufficio) => {
      const payload = {
        regione: values.regione,
        citta: values.citta,
        denominazione: values.denominazione,
        indirizzo: places,
        materiale: values.materiale,
        MTb: values.MTb,
        MTh: values.MTh,
        MQ: values.MQ,
        lat: location.lat,
        lng: location.lng
      }
      //send api update request here
      //use whatever fetch library you want, fetch, axios, etc
      axios.post(`${REACT_APP_API_ENDPOINT}/api/offices/create`, payload)
        .then(function (response) {
          console.log(response);

          axios.get(`${REACT_APP_API_ENDPOINT}/api/offices/getone`,
            { params: { _id: response.data._id } })
            .then(function (response) {
              console.log(response);
              return response
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },


    onMutate: (newUfficioInfo) => {

      queryClient.setQueryData(['uffici'], (prevUffici) =>
        prevUffici?.map((prevUfficio) =>
          prevUfficio._id === newUfficioInfo._id ? newUfficioInfo : prevUfficio,
        ),
      );
    },

    onSuccess: async (data, variables, context) => {
      console.log("I'm first!", data, variables, context)
      queryClient.invalidateQueries({ queryKey: ['uffici'] })
    },

    onSettled: () => queryClient.invalidateQueries({ queryKey: ['uffici'] }),


    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['uffici'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['uffici'],
    queryFn: async () => {
      const fetchURL = new URL(`${REACT_APP_API_ENDPOINT}/api/offices/offices_all`);
      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL);
      const json = (await response.json());
      return json.data;
    },
    refetchOnWindowFocus: true,
  });
}

const handleExportData = (rows) => {
  if (!rows || rows.length === 0) {
    alert("Nessun dato da esportare!");
    return;
  }

  const rowData = rows.map((row) =>
    Object.fromEntries(
      Object.entries(row.original).map(([key, value]) => [
        key,
        typeof value === "object" && value !== null ? JSON.stringify(value) : value,
      ])
    )
  );

  // Genera e scarica il CSV
  // Configurazione CSV con nome personalizzato
  const csvConfig3 = { ...csvConfig, filename: "uffici_esportati" };

  // Genera e scarica il CSV
  const csv = generateCsv(csvConfig3)(rowData);
  download(csvConfig3)(csv);
  /*   const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv); */
};




//UPDATE hook (put user in api)
function useUpdateUfficio() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ufficio) => {
      //send api update request here
      axios.post(`${REACT_APP_API_ENDPOINT}/api/offices/update`, ufficio)
        .then(function (response) {
          console.log(response);
          return response
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //client side optimistic update
    onMutate: (newUfficioInfo) => {
      queryClient.setQueryData(['uffici'], (prevUffici) =>
        prevUffici?.map((prevUfficio) =>
          prevUfficio._id === newUfficioInfo._id ? newUfficioInfo : prevUfficio,
        ),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['uffici'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUfficio() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ufficioId, rowId) => {
      //send api update request here
      return axios.delete(`${REACT_APP_API_ENDPOINT}/api/offices/delete`, {
        params: { _id: ufficioId },
      })
        .then(function (response) {
          console.log(response);
          //return response
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //client side optimistic update
    onMutate: (ufficioId, rowId) => {
      queryClient.setQueryData(['uffici'], (prevUffici) =>
        prevUffici?.filter((ufficio) => ufficio.id !== rowId),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['uffici'] }), //refetch users after mutation, disabled for demo
  });
}


const Uffici = () => {

  //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
  return (

    <QueryClientProvider client={queryClient}>

      <UfficiAll />

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>

  )
};

export default Uffici;

const validateRequired = (value) => !!value.length;


function validateUfficio(ufficio) {
  return {
    /* denominazione: !validateRequired(ufficio.denominazione)
      ? 'Denominazione Name is Required' : '',
      indirizzo: !validateRequired(ufficio.indirizzo) ? 'Indirizzo is Required' : '',
      materiale: !validateRequired(ufficio.materiale) ? 'Materiale is Required' : '',
      MTb: !validateRequired(ufficio.MTb) ? 'MTb is Required' : '',
      MTh: !validateRequired(ufficio.MTh) ? 'MTh is Required' : '',
      MQ: !validateRequired(ufficio.MQ) ? 'MQ is Required' : '', */
    //  lat: !validateRequired(ufficio.lat) ? 'lat is Required' : '',
    //  lng: !validateRequired(ufficio.lng) ? 'lng is Required' : '',
  };
}
