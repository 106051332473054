import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Container, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import StoreIcon from '@mui/icons-material/Store';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { getAffissioniRegions, getAffissioniCities } from './apiAffisioniStat';
import TableStatManageAffissioni from './TableStatManagedAffissioni';

const { REACT_APP_API_ENDPOINT } = process.env;

const ManagedCampaingnAffissioniDash = () => {
  const [campaignMenu, setCampaignMenu] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState('');
  const [campaignData, setCampaignData] = useState([]);
  const [campaignOveralData, setCampaignOveralData] = useState({});
  const [affissioniRegions, setAffissioniRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCampaignMenu = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni-menu`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCampaignMenu(response.data);
      } catch (err) {
        setError('Errore nel caricamento del menu delle campagne');
      } finally {
        setLoading(false);
      }
    };
    fetchCampaignMenu();
  }, []);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await getAffissioniRegions();
        setAffissioniRegions(response);
      } catch {
        setError('Errore nel caricamento delle regioni');
      }
    };
    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      if (selectedRegion) {
        try {
          const response = await getAffissioniCities(selectedRegion);
          setCities(response);
        } catch {
          setError('Errore nel caricamento delle città');
        }
      }
    };
    fetchCities();
  }, [selectedRegion]);

  const fetchCampaignData = async (campaignId) => {
    try {
      setLoading(true);
      const params = { regione: selectedRegion || null, citta: selectedCity || null };
      const queryString = new URLSearchParams(params).toString();
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni/${campaignId}/progress?${queryString}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setCampaignData(response.data);
    } catch {
      setError('Errore nel caricamento dei dati della campagna');
      setCampaignData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchOverallProgress = async (campaignId) => {
    try {
      const params = { regione: selectedRegion || null, citta: selectedCity || null };
      const queryString = new URLSearchParams(params).toString();
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni/${campaignId}/overall-progress?${queryString}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setCampaignOveralData(response.data[0]);
    } catch {
      setError('Errore/ Nessun dato trovato nel caricamento del progresso complessivo');
      setCampaignOveralData({});
    }
  };

  const handleFilter = () => {
    if (campaignSelected) {
      fetchCampaignData(campaignSelected);
      fetchOverallProgress(campaignSelected);
    } else {
      setError('Seleziona una campagna per continuare');
    }
  };

  const MenuCampagne = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2, // Spaziatura tra i menu
        marginBottom: '25px',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="campagna-label">Seleziona Campagna</InputLabel>
        <Select value={campaignSelected} onChange={(e) => setCampaignSelected(e.target.value)}
        labelId="campagna-select-label"
        label={"Seleziona Campagna"}
        >
          {campaignMenu.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              {option.denominazione} - {option.anno}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  
      <FormControl fullWidth>
        <InputLabel id="regione-label">Seleziona Regione</InputLabel>
        <Select 
        value={selectedRegion} 
        onChange={(e) => setSelectedRegion(e.target.value)}
        labelId="regione-select-label"
        label={"Seleziona Regione"}
        >
          {affissioniRegions.map((region) => (
            <MenuItem key={region} value={region}>
              {region}
            </MenuItem>
          ))}
          <MenuItem value={''} onChange={(e) => setSelectedRegion('')} style={{color:'#058e08', fontWeight:'bolder'}}>NULL</MenuItem>
        </Select>
      </FormControl>
  
      <FormControl fullWidth>
        <InputLabel id="citta-label">Seleziona Città</InputLabel>
        <Select
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          disabled={!selectedRegion} // Disabilita se la regione non è selezionata
          labelId="citta-select-label"
        label={"Seleziona Città"}
        >
          {cities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          ))}
          <MenuItem value={''} onChange={(e) => setSelectedCity('')} style={{color:'#058e08', fontWeight:'bolder'}}>NULL</MenuItem>
        </Select>
      </FormControl>
  
      <Button variant="contained" onClick={handleFilter}>
        Filtra Dati
      </Button>
    </Box>
  );
  

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Stack direction="row" alignItems="center" gap={2} sx={{ mb: 4 }}>
        <StoreIcon />
        <Typography variant="h6">Dashboard Progresso Campagna Uffici</Typography>
      </Stack>
      {MenuCampagne()}
      {error && <Typography color="error">{error}</Typography>}
      {/* 
        [
    {
        "_id": "67069fca2e3c208af46a83f7",
        "ufficiCompletati": 2,
        "totaleMQApplicati": 11.33,
        "MQtotaleDaCoprire": 11.33,
        "totalUffici": 2,
        "ufficiInfo": {
            "_id": null,
            "totaliMq": 2193.29,
            "totUffici": 440
        },
        "diferenzaMq": 2181.96,
        "diferenzaUffici": 438,
        "completionRate": 0.45454545454545453
    }
]
        
        */}
        {campaignOveralData.ufficiCompletati ? 
          <>
      <Typography variant="h6" sx={{ mt: 4 }}>
       ✅ Completati: {campaignOveralData?.ufficiCompletati || 0} - 🏠 Uffici: {campaignOveralData?.ufficiInfo.totUffici || 0} - 🧽 MQ Applicati: {campaignOveralData?.totaleMQApplicati.toFixed(2) || '0'} - 🪜 MQ Rimanenti: {campaignOveralData?.diferenzaMq} - 🧤 Uff Rimanenti: {campaignOveralData?.diferenzaUffici}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={campaignOveralData.completionRate} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${parseFloat(campaignOveralData?.completionRate).toFixed(2)}%`}
        </Typography>
      </Box>
    </Box>
      
      </>
      : null
    }
    <div style={{ width: '100%' }}  >
    <TableStatManageAffissioni val={campaignData} />

    </div>
    </Container>
  );
};

export default ManagedCampaingnAffissioniDash;
