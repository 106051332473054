import * as React from 'react';

import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import { itIT } from '@mui/x-data-grid/locales';
const paginationModel = { page: 0, pageSize: 15 };



 function TableStatManageAffissioni(props) {

    const [columnVisibilityModel] = React.useState({
        id: false,
      });

      /*
{
        "denominazione": "ROMA 130",
        "completed": 1,
        "in_progress": 0,
        "total": 1,
        "percentage": 1,
        "media": 100
    },
      */

    const columns = [
        { field: 'id', headerName: 'RowID', width: 70, hidden: false },
        { field: 'denominazione', headerName: 'Ufficio', width: 300 },
        { field: 'in_progress', headerName: 'Progress', width: 170, hidden: false, headerAlign: 'center',  align:'center' },
        { field: 'media', headerName: 'media', width: 100, headerAlign: 'center',  align:'center',
           cellClassName: (params) => {
          return "super-app";
        },
        renderCell: (params) => {
         // const color = getColor(params.value);
          return (
            <Box
              sx={{
                backgroundColor: '#45f22694',
                width: "100%",
                height: "100%",
              }}
            >
              {params.value.toFixed(2)}
            </Box>
          );  
        },},
        { field: 'percentage', headerName: '% completamento', hidden: true, width: 250 },
        { field: 'total', headerName: 'ToT.', width: 130, headerAlign: 'center',  align:'center'
          ,
            cellClassName: (params) => {
                return "super-app";
              },
              renderCell: (params) => {
               // const color = getColor(params.value);
          
                return (
                  <Box
                    sx={{
                      backgroundColor: '#e6f22694',
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {params.value}
                  </Box>
                );
              },
         },
        { field: 'completed', headerName: 'completed', width: 140, headerAlign: 'center',  align:'center',
            cellClassName: (params) => {
                return "super-app";
              },
              renderCell: (params) => {
               // const color = getColor(params.value);
          
                return (
                    {...params.value === 0 ?
                        <Box
                        sx={{
                          backgroundColor: '#f00549c7',
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {params.value}
                      </Box>
                       : 
                       <Box
                       sx={{
                         backgroundColor: '#d526f24f',
                         width: "100%",
                         height: "100%",
                       }}
                     >
                       {params.value}
                     </Box>}

                );
              },

         }
    ];

    const rows = [];


      for (let index = 0; index < props.val.length; index++) {
        rows.push({ 
          id: index, 
          denominazione: props.val[index].denominazione,
          in_progress: props.val[index].in_progress,
          media: props.val[index].media,
          percentage: props.val[index].percentage, 
          total: props.val[index].total,
        //  completed: props.val[index].complete === true ? "100% completato" : "No"});
          completed: props.val[index].completed});
      }

  return (
    <Paper sx={{  width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={
          { pagination: { paginationModel },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              garage: true,
              in_progress: true,
              media: true,
              percentage: false,
              total:true,
              completed: true
            },
          },
       }}
        pageSizeOptions={[5, 10]}
      //  columnVisibilityModel={columnVisibilityModel}
        density="compact"
       // checkboxSelection
       slots={{
        toolbar: GridToolbar,
      }}
        sx={{ border: 0 }}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </Paper>
  );
}
export default TableStatManageAffissioni;